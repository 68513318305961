
    import Vue from 'vue'
    const CopyRight = () => import('../CopyRight.vue')

    export default Vue.extend({
        name: 'PortalFooter',
        components: {
            CopyRight
        },
        data () {
            return {
            }
        }
    })
